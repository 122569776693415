/**
 * This file with its imports is being loaded in the security.ejs on production using webpack.
 * While working locally, the last bundle is being loaded in the security.ejs as minified + uglyfied code.
 * To see changes from your code locally, after each change you need to run "npx webpack" from the package folder and copy
 * the content of the generated file in "dist/handleAccessTokens.js" to the security.ejs file.
 * This is only because yoshi does let us to remove the loaded webpack-dev-server into the bundle and it causes errors locally only.
 */

import { guardCookie, guardCookieStore, getAndDeleteClientSessionValue } from './cookieGuard'

const CLIENT_HEADER = 'client-binding'
const THUNDERBOLT_READY_EVENT_NAME = 'tbReady'
const SECURITY_ERROR_TYPE = 'security_overrideGlobals'

// @ts-expect-error
const { experiments, siteFeaturesConfigs, accessTokensUrl } = window.viewerModel

const accessTokensEndpoint = accessTokensUrl

const fetchHeaders: Record<string, string> = {}
const xsrfValue = getAndDeleteClientSessionValue()
if (xsrfValue) {
	fetchHeaders[CLIENT_HEADER] = xsrfValue
}

let originalFetch = fetch

function initOnTbReady(event: Event) {
	// @ts-expect-error
	const { logger } = event.detail
	try {
		// @ts-expect-error
		window.tb.init({ fetch: originalFetch, fetchHeaders })
	} catch (e) {
		const error = new Error('TB003')
		logger.captureError(error, { tags: { feature: 'thunderbolt-security' } })
		logger.meter(`${SECURITY_ERROR_TYPE}_${error.message}`, {
			paramsOverrides: {
				evid: '26',
				errorType: SECURITY_ERROR_TYPE,
				eventString: error.message,
			},
		})

		// @ts-expect-error
		if (window?.viewerModel?.mode.debug) {
			console.error(e)
		}
	} finally {
		removeEventListener(THUNDERBOLT_READY_EVENT_NAME, initOnTbReady)
		// This is done to remove the reference to the original fetch and use the overridden one
		originalFetch = fetch
	}
}

addEventListener(THUNDERBOLT_READY_EVENT_NAME, initOnTbReady)

if (!experiments['specs.thunderbolt.hardenFetchAndXHR']) {
	// @ts-expect-error
	window.fetchDynamicModel = () =>
		siteFeaturesConfigs.sessionManager.isRunningInDifferentSiteContext
			? Promise.resolve({})
			: fetch(accessTokensEndpoint, { credentials: 'same-origin', headers: fetchHeaders }).then(function (r) {
					if (!r.ok) {
						throw new Error(`[${r.status}]${r.statusText}`)
					}
					return r.json()
			  })
	// @ts-expect-error
	window.dynamicModelPromise = window.fetchDynamicModel()
}

// Protect reading and writing security related cookies
guardCookie()

if (experiments['specs.thunderbolt.hardenCookieStoreAccess']) {
	guardCookieStore()
}
